



<template>
  <div>
    <v-layout wrap>
      <v-flex xs6>
        <v-layout wrap justify-center>
          <v-flex xs6 align-self-center>
            <v-card class="grad" height="150px">
              <v-flex align-self-center pt-16 class="poppinsbold2">
                <center>
                  <span>
                    COUNT=
                    {{ count.usersCount }}
                  </span>
                </center>
              </v-flex>
            </v-card></v-flex
          >
        </v-layout>
      </v-flex>

      <v-flex xs6>
        <v-layout wrap justify-center>
          <v-flex xs6 align-self-center>
            <v-card class="grad" height="150px">
              <v-flex align-self-center pt-16 class="poppinsbold2">
                <center>
                  RECORDS=
                  {{ count.recods }}
                </center>
              </v-flex>
            </v-card></v-flex
          >
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>



<script>
import axios from "axios";

// import store from "./../../store";
export default {
  data() {
    return {
      msg: "",

      timeout: 5000,
      count: "",
      snackbar: false,
      appLoading: false,
      contact: {
        name: null,
        email: null,
        phno: null,

        message: null,
      },
    };
  },

  mounted() {
    this.getcount();
  },

  methods: {
    getcount() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/media/admin/dashboard",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.count = response.data;
          this.appLoading = false;
          console.log("list=", response.data.data);
        })

        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
  },
};
</script>
























<style>
.grad {
  background-image: linear-gradient(to right, #7fff00, #228b22);
}
</style>




